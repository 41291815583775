<template>
  <div class="container">
    <pcPublicHeader :pageKey="'class'" :goodsName="goodsName" @handleSearchSubmit="handleSearchSubmit" />
    <div class="public-content">
      <div class="class-container">
        <div v-if="classList && classList.length > 0" class="class-list">
          <van-tabs type="card" :line-width="0" :line-height="0" :swipe-threshold="1" @click="handleClassChange($event, 0)">
            <van-tab :name="0">
              <div slot="title" class="class-item">全部</div>
            </van-tab>
            <van-tab v-for="(item, index) in classList" :key="index" :name="index + 1">
              <div slot="title" class="class-item">
                <div class="icon img-cover"><img :src="item.CategoryThumb" /></div>
                {{ item.Name }}
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div v-if="selectedClassData[0] && classList[selectedClassData[0] - 1].Childern && classList[selectedClassData[0] - 1].Childern.length > 0" class="class-list">
          <van-tabs type="card" :line-width="0" :line-height="0" :swipe-threshold="1" @click="handleClassChange($event, 1)">
            <van-tab :name="0">
              <div slot="title" class="class-item">全部</div>
            </van-tab>
            <van-tab v-for="(item, index) in classList[selectedClassData[0] - 1].Childern" :key="index" :name="index + 1">
              <div slot="title" class="class-item">
                <!-- <div class="icon img-cover"><img :src="item.CategoryThumb" /></div> -->
                {{ item.Name }}
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div v-if="selectedClassData[1] && classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Childern && classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Childern.length > 0" class="class-list">
          <van-tabs type="card" :line-width="0" :line-height="0" :swipe-threshold="1" @click="handleClassChange($event, 2)">
            <van-tab :name="0">
              <div slot="title" class="class-item">全部</div>
            </van-tab>
            <van-tab v-for="(item, index) in classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Childern" :key="index" :name="index + 1">
              <div slot="title" class="class-item">
                <!-- <div class="icon img-cover"><img :src="item.CategoryThumb" /></div> -->
                {{ item.Name }}
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <div v-if="(selectedClassData[1] && classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Bq) || (selectedClassData[1] && selectedClassData[2] && classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Childern[selectedClassData[2] - 1].Bq)" class="bq-container"> 
        <div class="bq-item" :class="bqValue == '' ? 'active' : ''" @click="bqValue = ''">全部</div>
        <template v-if="selectedClassData[1] && classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Bq">
          <div class="bq-item" :class="bqValue == item ? 'active' : ''" v-for="(item, index) in setBqArr(classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Bq)" :key="index" @click="bqValue = item">{{ item }}</div>
        </template>
        <template v-if="selectedClassData[1] && selectedClassData[2] && classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Childern[selectedClassData[2] - 1].Bq">
          <div class="bq-item" :class="bqValue == item ? 'active' : ''" v-for="(item, index) in setBqArr(classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Childern[selectedClassData[2] - 1].Bq)" :key="index" @click="bqValue = item">{{ item }}</div>
        </template>
      </div>
      <div class="gg-container" @click="handleShowGg(classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].TanContent)" v-if="selectedClassData[0] && selectedClassData[1] && classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].TanContent">
        <div class="icon img-cover"><img src="../../assets/pc-images/msg-icon.png" /></div>
        <div class="detail">{{ classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].TanContent }}</div>
      </div>
      <div class="goods-container">
        <div v-if="goodsList.length == 0 && finish && !loading" class="empty-item">
          <div class="img img-cover">
            <img src="../../assets/pc-images/empty-img.png" />
          </div>
        </div>
        <div v-else class="goods-list">
          <router-link  :to="{path: '/goodsDetail', query: {id: item.Id}}" class="goods-item" v-for="(item, index) in goodsList" :key="index">
            <div class="img-item img-cover">
              <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
              <img v-else src="../../assets/images/no-image-icon.png" />
            </div>
            <div class="detail-item">
              <div class="name">
                <span class="tag" v-if="item.Lable">{{ item.Lable }}</span>
                <span class="word">{{ item.GoodsName }}</span>
              </div>
              <div class="money">{{ parseFloat(item.GoodsPrice).toFixed(4) }}</div>
            </div>
          </router-link>
        </div>
        <div v-if="total > pageCount" class="pagination-item">
          <el-pagination background layout="prev, pager, next" :page-size="pageCount" :total="total" @current-change="handleCurrentPageChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'

export default {
  name: "Class",
  components: {
    pcPublicHeader
  },
  data() {
    return {
      queryId: '',
      classList: [],
      selectedClassData: [0, 0, 0],
      bqValue: '',
      goodsName: '',
      goodsList: [],
      pageCount: 21,
      page: 1,
      total: 0,
      finish: false,
      loading: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    bqValue(val) {
      this.bqValue = val
      this.handleReGetGoodsList()
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.queryId = this.$route.query.id
    }
    if (this.$route.query && this.$route.query.searchKey) {
      this.goodsName = this.$route.query.searchKey
    }
    this.getClassList()
  },
  methods: {
    handleClassChange(val, type) {
      const { selectedClassData } = this
      if (type == 0) {
        this.selectedClassData = [val, 0, 0]
      }
      if (type == 1) {
        this.selectedClassData = [selectedClassData[0], val, 0]
        if (val) {
          const { classList } = this
          if (classList[selectedClassData[0] - 1].Childern[val - 1].IsTan == 1 && classList[selectedClassData[0] - 1].Childern[val - 1].TanContent) {
            this.handleShowGg(classList[selectedClassData[0] - 1].Childern[val - 1].TanContent)
          }
        }
      }
      if (type == 2) {
        this.selectedClassData = [selectedClassData[0], selectedClassData[1], val]
      }
      this.bqValue = ''
      this.handleReGetGoodsList()
    },
    handleShowGg(data) {
      this.$dialog.alert({
        type: 'danger',
        className: 'pc-dialog',
        title: '下单必看',
        message: data,
        confirmButtonText: '确定'
      })
    },
    setBqArr(str) {
      return str.split(',')
    },
    async getClassList() {
      var res = await this.$API.goodsCategoryList.post({
        IsAll: 1
      });
      const data = res.info
      if (data && data.length > 0) {
        const { queryId } = this
        if (queryId) {
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            if (item.Id == queryId) {
              this.selectedClassData = [index + 1, 0, 0]
              break
            }
          }
        }
        this.classList = data
        this.getGoodsList()
      }
    },
    handleSearchSubmit(val) {
      this.goodsName = val
      this.handleReGetGoodsList()
    },
    handleReGetGoodsList() {
      this.goodsList = []
      this.page = 1
      this.total = 0
      this.finish = false
      this.loading = false
      this.getGoodsList()
    },
    handleCurrentPageChange(val) {
      this.page = val
      this.getGoodsList()
    },
    async getGoodsList() {
      const { userInfo } = this
      if (userInfo && userInfo.Id && userInfo.UserIsGoods != 1) {
        this.finish = true
        this.loading = false
        return
      }
      const { loading, pageCount, page, selectedClassData, classList, goodsName, bqValue, websiteInfo } = this
      if (loading) {
        return
      }
      const params = {
        PageCount: pageCount, 
        Page: page,
        AdminId: websiteInfo.Id
      }
      if (goodsName) {
        params.GoodsName = goodsName
      }
      if (bqValue) {
        params.Bq = bqValue
      }
      if (selectedClassData[0]) {
        params.CategoryId = classList[selectedClassData[0] - 1].Id
      }
      if (selectedClassData[1]) {
        params.CategoryId2 = classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Id
      }
      if (selectedClassData[2]) {
        params.CategoryId3 = classList[selectedClassData[0] - 1].Childern[selectedClassData[1] - 1].Childern[selectedClassData[2] - 1].Id
      }
      var res = await this.$API.goodsList.post(params)
      if (res.info && res.info.length > 0) {
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index]
          res.info[index].Bq = item.Bq ? item.Bq.split(',') : []
        }
      }
      this.goodsList = [
        ...res.info
      ]
      this.total = res.count
      this.finish = res.info.length < pageCount
      this.loading = false
    },
    async handleCollection(item, index) {
      var res = await this.$API.addGoodsCollect.post({
        GoodsId: item.Id
      })
      if (res.error == 0) {
        this.goodsList[index].IsCollect = item.IsCollect > 0 ? 0 : 1
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
      return false
    }
  }
};
</script>

<style lang="less" scoped>

.class-container {
  margin-top: 30px;
  padding-bottom: 8px;
  border-bottom: 1px solid #cbcccc;
  .class-list {
    margin-bottom: 16px;
    /deep/ .van-tabs {
      background-color: transparent;
      &>.van-tabs__wrap {
        height: 42px;
      }
      .van-tabs__wrap--scrollable {
        .cover {
          display: none;
        }
        .van-tabs__nav--card {
          padding: 0;
          height: 42px;
          margin: 0;
          border: 0;
          background-color: transparent;
        }
        .van-tab {
          flex: none !important;
          background-color: transparent;
          border: 0;
          margin-right: 14px;
          padding: 0 12px;
          background-color: #fff;
          border: 1px solid #fff;
          border-radius: 6px;
          height: 42px;
          color: #000;
          &.van-tab--active {
            border-color: #6a5ffe;
            background-color: rgba(118,131,255,0.16);
            color: #6a5ffe;
          }
        }
      }
    }
    .class-item {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      .icon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
  }
}
.bq-container {
  margin-top: 16px;
  overflow: hidden;
  .bq-item {
    cursor: pointer;
    float: left;
    line-height: 38px;
    color: #3d3d42;
    margin: 8px 20px 0;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:nth-last-child(1) {
      margin-right: 0;
    }
    &.active {
      color: #6a5ffe;
      font-weight: bold;
    }
  }
}
.gg-container {
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
  height: 42px;
  border-radius: 6px;
  background: rgba(106, 95, 254, .12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    margin: 0 8px 0 12px;
    width: 30px;
    height: 30px;
  }
  .detail {
    margin-right: 12px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #3d3d42;
    font-size: 14px;
  }
}
.goods-container {
  margin-top: 20px;
  .goods-list {
    overflow: hidden;
  }
  .goods-item {
    width: 194px;
    margin: 0 20px 20px 0;
    float: left;
    &:nth-child(7n) {
      margin-right: 0;
    }
    .img-item {
      width: 194px;
      height: 194px;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
    }
    .detail-item {
      background-color: #fff;
      border-radius: 0 0 12px 12px;
      padding: 10px 12px 14px 12px;
      .name {
        height: 36px;
        line-height: 18px;
        font-size: 14px;
        color: #000;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        .tag {
          margin-right: 6px;
          display: block;
          float: left;
          padding: 0 8px;
          background: linear-gradient( 90deg, #6a5ffe 0%, #21e8fe 100%);
          height: 18px;
          line-height: 18px;
          font-size: 10px;
          color: #fff;
        }
      }
      .money {
        margin-top: 10px;
        line-height: 24px;
        font-size: 20px;
        color: #f00;
        font-weight: 400;
      }
    }
  }
}
</style>
